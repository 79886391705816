import { Routes } from '@angular/router';
import { LayoutComponent } from './Pages/Shared/layout/layout.component';
import { AboutComponent } from './Pages/about/about.component';
import { HomeComponent } from './Pages/home/home.component';
import { CargohandlingserviceComponent } from './Pages/services/CargoHandlingService/cargohandlingservice.component';
import { ContactComponent } from './Pages/contact/contact.component';
import { GroundserviceComponent } from './Pages/services/GroundServices/groundservice.component';
import { LocationComponent } from './Pages/location/location.component';
import { NewsComponent } from './Pages/news/news.component';
import { OurteamComponent } from './Pages/ourteam/ourteam.component';
import { CareersComponent } from './Pages/careers/careers.component';
import { ClientsComponent } from './Pages/Clients/clients.component';
import { CooperativeCLientsComponent } from './Pages/CorporateClients/cooperative-clients.component';
import { TickettravelserviceComponent } from './Pages/services/TicketTravelService/tickettravelservice.component';
import { AssociatecompanyComponent } from './Pages/AssociateCompany/associatecompany.component';

export const routes: Routes = [
  {     
    path: '',
    loadChildren: () => import('./Pages/Shared/layout/layout.routes').then(p => p.routes)
},
    // {
    //     path: '', component: LayoutComponent, children: [
    //       { path: 'Home', component: HomeComponent },
    //       { path: 'Location', component: LocationComponent },
    //       { path: 'About', component: AboutComponent },
    //       { path: 'Career', component: CareersComponent },
    //       { path: 'News', component: NewsComponent },
    //       { path: 'Contact', component: ContactComponent },
    //       { path: 'OurTeam', component: OurteamComponent },
    //       { path: 'Clients', component: ClientComponent },
    //       { path: 'CorporateClients', component: CorporateclientComponent },
    //       { path: 'CargoHandlingService', component: CargohandlingserviceComponent },
    //       { path: 'Groundservice', component: GroundserviceComponent },
    //       { path: 'TicketTravel', component: TickettravelComponent },
    //       { path: 'CargoSales', component: CargohandlingserviceComponent },
    //       { path: 'Services/:id', component: GroundserviceComponent },
    //       { path: 'Location/:id', component: LocationComponent },
    //       { path: 'Associate', component: AssociateComponent },
    //     ]
    //   },
];
