
  <router-outlet></router-outlet>

 <!-- <a class="fixedButton" title="Contact Us" href (click)="addMessage()" data-toggle="modal" data-target="#messageModal"
  style="cursor: pointer;">
  <div class="roundedFixedBtn"><i class="fa fa-envelope"></i></div>
</a>

<div class="modal fade come-from-modal right " id="messageModal" data-backdrop="static" data-keyboard="false"
  tabindex="-1" role="dialog" aria-labelledby="assignedGroupRoleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl mblshow">
      <div class="modal-content asdasdasd">
          <div class="modal-header">
              <div class="row">
                  <div class="col-md-12">
                      <h5 class="modal-title text-center" id="exampleModalLabel"><b>CONTACT US</b></h5><br>
                  </div>
                  <div class="col-md-12">
                      <p class="text-center">Our teams are ready to assist you. Send us a message and we will get back
                          to you, asap</p>
                  </div>
              </div>
          </div>
          <div class="modal-body">
              <form [formGroup]="MessageForm">
                  <div class="col-md-12">
                      <div class="form-group">
                          <input type="text" formControlName="name" placeholder="Name*" autocomplete="off"
                              class="form-control" [ngClass]="{ 'is-invalid': submitted && MessageForm.controls.name.errors }">
                          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                              <div *ngIf="MessageForm.controls.name.required">Name is required!</div>
                              <small *ngIf="MessageForm.controls.name.errors.whitespace && !MessageForm.controls.name.errors.required">Please
                                  enter
                                  valid
                                  data</small>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div class="form-group">
                          <input type="Email" formControlName="emailAddress" placeholder="Email*" autocomplete="off"
                              class="form-control" [ngClass]="{ 'is-invalid': submitted && f.emailAddress.errors }">
                          <div *ngIf="submitted && f.emailAddress.errors" class="invalid-feedback">
                              <div *ngIf="f.emailAddress.errors.required">Email address is required!</div>
                              <small *ngIf="f.emailAddress.errors.pattern">Email format not correct.</small>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div class="form-group">
                          <textarea autocomplete="off" class="form-control" rows="5" formControlName="message"
                              placeholder="Message*" type="text"
                              [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                          <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                              <div *ngIf="f.message.errors.required">Message is required!</div>
                              <small *ngIf="f.message.errors.whitespace && !f.message.errors.required">Please
                                  enter
                                  valid
                                  data</small>
                          </div>
                      </div>
                  </div> -->

                  <!-- <div class="col-md-6">
                      <ngx-recaptcha2 #captchaElem siteKey="6LfdBTYgAAAAAKBCq6ZoKssjSWKmbEP0irYaSSlw"
                          class="form-control" (success)="handleSuccess($event)" [size]="size" [hl]="lang"
                          [theme]="theme" [type]="type" formControlName="recaptcha" class="mb-2">
                      </ngx-recaptcha2>
                  </div> -->
<!-- 
                  <div class="modal-footer">
                      <button type="button" class="btn btn-primary" (click)="submitForm()">
                          <ng-container [ngTemplateOutlet]="clicked?loader:noLoader"></ng-container>
                          <ng-template #noLoader>
                              Send
                          </ng-template>
                          <ng-template #loader>
                              <img src="assets/Slider/ajax-loader.gif" style="height: 21px;margin-right:8px;"><span>Please wait..</span>
                          </ng-template>
                      </button>
                      <button id="hideModal" type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                  </div>
              </form>
          </div>
      </div>
  </div>
</div>  -->